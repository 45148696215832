import * as React from 'react';
import { useState, FormEvent, MouseEvent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import Loader from '../components/Loader';
import { useAuth, useSnackbar } from '../hooks';

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="https://4u-logistics.com/">
        4U Logistics
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default function Login() {
  const [isLoginError, setLoginError] = useState<boolean>(false);
  const [isEmptyEmailError, setEmptyEmailError] = useState<boolean>(false);
  const [isEmptyPassError, setEmptyPassError] = useState<boolean>(false);
  const [isAutentificating, setIsAutentificating] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const auth = useAuth();
  const setSnackbarMessage = useSnackbar();
  const theme = useTheme();

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleClose = () => {
    setEmptyEmailError(false);
    setEmptyPassError(false);
    setLoginError(false);
  };
  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;
    const remember = data.get('remember') as string;
    if (email && password) {
      setIsAutentificating(true);
      auth.signin(email, password, (res) => {
        setIsAutentificating(false);
        if (res) {
          setLoginError(false);
          if (remember) {
            localStorage.setItem('user', JSON.stringify({ email, password }));
          } else {
            sessionStorage.setItem('user', JSON.stringify({ email, password }));
          }
          navigate(from, { replace: true });
        } else {
          setLoginError(true);
          setSnackbarMessage({
            severity: 'error',
            message: 'Login or Password is incorrect!',
            handleClose,
          });
        }
      });
    } else {
      if (!email) {
        setEmptyEmailError(true);
      }
      if (!password) {
        setEmptyPassError(true);
      }
      if (!email || !password) {
        setSnackbarMessage({
          severity: 'error',
          message: `${!email ? 'Login' : ''}${
            !email && !password ? ' and ' : ''
          }${!password ? 'Password' : ''} must not be empty!`,
          handleClose,
        });
      }
    }
  };

  const from = location.state?.from?.pathname || '/';
  const user = JSON.parse(
    localStorage.getItem('user') || sessionStorage.getItem('user') || 'null',
  ) as { email: string; password: string };

  useEffect(() => {
    if (user && !auth.user) {
      setIsAutentificating(true);
      auth.signin(user.email, user.password, (res) => {
        if (res) {
          navigate(from, { replace: true });
        } else {
          sessionStorage.removeItem('user');
          localStorage.removeItem('user');
        }
        setIsAutentificating(false);
      });
    }
  }, []);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        backgroundColor: theme.palette.login.main,
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src="/4U_Red.png" />
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <Typography component="h1" variant="h5">
              Login
            </Typography>
            <Typography component="h1" variant="subtitle1">
              Enter your email and password to login
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              error={isLoginError || isEmptyEmailError}
              disabled={isAutentificating}
            />
            <FormControl variant="outlined" margin="normal" required fullWidth>
              <InputLabel
                htmlFor="password"
                error={isLoginError || isEmptyPassError}
              >
                Password
              </InputLabel>
              <OutlinedInput
                id="password"
                name="password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
                autoComplete="current-password"
                error={isLoginError || isEmptyPassError}
                disabled={isAutentificating}
              />
            </FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  value="remember"
                  color="primary"
                  disabled={isAutentificating}
                />
              }
              id="remember"
              name="remember"
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isAutentificating}
            >
              Log in
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
        <Loader visible={isAutentificating} />
      </Container>
    </div>
  );
}
