import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

import Autocomplete, {
  AutocompleteInputChangeReason,
} from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { debounce } from '@mui/material/utils';

import { useSnackbar, useLoader } from '../hooks';
import { toStringLocation } from '../utils/toStringLocation';
import { selectLocation } from '../utils/selectLocation';
import { getMapSettings } from '../utils/getMapSettings';
import { getGeoPointFromLocation } from '../utils/getGeoPointFromLocation';

export default function SearchLocationFieldControl({
  fieldName,
  label,
  placeholder,
  filterSelectedOptions = false,
  disabled,
  formik,
}: {
  readonly fieldName: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly filterSelectedOptions?: boolean;
  readonly disabled?: boolean;
  readonly formik: ReturnType<typeof useFormik<any>>;
}) {
  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  const [value, setValue] = React.useState<google.maps.GeocoderResult | null>(
    null,
  );
  const [items, setItems] = React.useState<google.maps.GeocoderResult[]>([]);
  const [geocoder, setGeocoder] = React.useState<google.maps.Geocoder | null>(
    null,
  );
  const [options, setOptions] = React.useState<
    readonly google.maps.GeocoderResult[]
  >([]);

  const setSearch = React.useMemo(() => {
    return debounce((geocodeRequest: google.maps.GeocoderRequest): void => {
      (geocoder?.geocode(geocodeRequest) || Promise.reject())
        .then((result) => {
          if (result.results.length < 1 && geocodeRequest.location) {
            const locationFromRequest = new google.maps.LatLng(
              geocodeRequest.location,
            );
            const res: google.maps.GeocoderResult = {
              address_components: [],
              formatted_address: `[${locationFromRequest.lat()}, ${locationFromRequest.lng()}]`,
              geometry: {
                location: locationFromRequest,
                location_type: google.maps.GeocoderLocationType.APPROXIMATE,
                viewport: new google.maps.LatLngBounds(),
              },
              place_id: `[${locationFromRequest.lat()}, ${locationFromRequest.lng()}]`,
              types: [],
            };
            setItems([res]);
          } else {
            result.results.forEach(
              (item) =>
                (item.formatted_address = toStringLocation(
                  item.address_components,
                )),
            );
            setItems(result.results);
          }
        })
        .catch((/*err*/) => {
          setItems([]);
        });
    }, 400);
  }, [geocoder]);

  React.useEffect(() => {
    if (value && !items.find((item) => item?.place_id === value?.place_id)) {
      setOptions([value, ...items]);
    } else {
      setOptions([...items]);
    }
  }, [items, value]);

  React.useEffect(() => {
    if (!formikValue) {
      setItems([]); // Clear items if value cleared from form
      setValue(null);
    }
  }, [formikValue]);

  React.useEffect(() => {
    const geoPoint = formikValue;
    if (
      geocoder &&
      geoPoint &&
      geoPoint[0] &&
      geoPoint[1] &&
      (value?.geometry?.location?.lat() !== formikValue[0] ||
        value?.geometry?.location?.lng() !== formikValue[1])
    ) {
      setSearch({
        location: {
          lat: geoPoint[0],
          lng: geoPoint[1],
        },
      });
    }
  }, [setSearch, formikValue]);

  React.useEffect(() => {
    if (
      formikValue &&
      (!value ||
        (value &&
          (value?.geometry?.location?.lat() !== formikValue[0] ||
            value?.geometry?.location?.lng() !== formikValue[1])))
    ) {
      const selected = selectLocation(items);
      if (selected) {
        setValue(selected);
        formik.setFieldValue(
          fieldName,
          getGeoPointFromLocation(selected),
          true,
        );
      }
    }
  }, [options]);

  const handleChange = (
    event: any,
    newValue: google.maps.GeocoderResult | null,
  ) => {
    const newCoordsValue = getGeoPointFromLocation(newValue);
    setValue(newValue);
    formik.setFieldValue(fieldName, newCoordsValue, true);
  };

  const isOptionEqualToValue = (
    value: google.maps.GeocoderResult,
    option: google.maps.GeocoderResult,
  ) => value?.place_id === option?.place_id;

  const handleInputChange = (
    event: any,
    newInputValue: string,
    reason: AutocompleteInputChangeReason, // 'input' | 'reset' | 'clear',
  ) => {
    if (reason === 'input') {
      setSearch({ address: newInputValue });
    }
  };

  const handleApiLoad = (status: Status): React.ReactElement => {
    const setSnackbarMessage = useSnackbar();
    const [, setLoaderVisibility] = useLoader();
    React.useLayoutEffect(() => {
      if (status === Status.LOADING) {
        setLoaderVisibility(true);
      }
      if (status === Status.FAILURE) {
        setSnackbarMessage({
          severity: 'error',
          message: 'Error loading maps!',
        });
      }
      if (status === Status.SUCCESS) {
        setGeocoder(new window.google.maps.Geocoder());
      }
      return () => {
        if (status === Status.LOADING) {
          setLoaderVisibility(false);
        }
      };
    }, [status]);
    return null as unknown as React.ReactElement;
  };

  return (
    <Box key={fieldName} sx={{ height: '75px' }}>
      <Wrapper {...getMapSettings()} render={handleApiLoad} />
      <Autocomplete
        disabled={disabled}
        id={fieldName}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.formatted_address || ''
        }
        filterOptions={(x) => x}
        options={options}
        isOptionEqualToValue={isOptionEqualToValue}
        autoComplete
        includeInputInList
        filterSelectedOptions={filterSelectedOptions}
        value={value}
        noOptionsText="No data"
        onChange={handleChange}
        onInputChange={handleInputChange}
        onClose={() => {
          queueMicrotask(() => formik.setFieldTouched(fieldName, true, true));
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            size="small"
            label={label}
            placeholder={placeholder}
            error={formikTouched && Boolean(formikError)}
            helperText={
              formikTouched &&
              formikError &&
              `${
                typeof formikError === 'string'
                  ? formikError
                  : 'Validation error'
              }`
            }
          />
        )}
        renderOption={(props, option) => (
          <li
            {...{ ...props, disabled }}
            key={(props as any).key || option.place_id}
          >
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', width: 44 }}>
                <LocationOnIcon sx={{ color: 'text.secondary' }} />
              </Grid>
              <Grid
                item
                sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}
              >
                <Box component="span" sx={{ fontWeight: 'regular' }}>
                  {option.formatted_address}
                </Box>
              </Grid>
            </Grid>
          </li>
        )}
      />
    </Box>
  );
}
