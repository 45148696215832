import { authProvider } from '../utils/auth';
import {
  Coordinator,
  CreateCoordinator,
  UpdateCoordinator,
  CreateCoordinatorDriver,
  UpdateCoordinatorDriver,
  Driver,
  CreateDriver,
  UpdateDriver,
  Load,
  CreateLoad,
  UpdateLoad,
  Location,
  CreateLocation,
  UpdateLocation,
  Owner,
  CreateOwner,
  UpdateOwner,
  CreateOwnerDriver,
  UpdateOwnerDriver,
  Truck,
  CreateTruck,
  UpdateTruck,
  User,
  CreateUser,
  UpdateUser,
  Query,
  QuerySingle,
  Result,
  TruckForMap,
  TruckStatus,
  File,
  Customer,
  CreateCustomer,
  UpdateCustomer,
  Facility,
  CreateFacility,
  UpdateFacility,
  Push,
  Email,
  FileOfType,
  LoadStatus,
} from '../utils/dataTypes';

const SUCCESS_HTTP_CODE = 200;
const CREATED_HTTP_CODE = 201;
const FORBIDDEN_HTTP_CODE = 403;
const NOT_FOUND_HTTP_CODE = 404;
const CONFLICT_HTTP_CODE = 409;

const { origin } = window.location;

const transformBody = (body: object): Record<string, any> => {
  const transformedBody: Record<string, any> = {};
  Object.entries(body).forEach((entry) => {
    if (Array.isArray(entry[1])) {
      transformedBody[entry[0]] = entry[1].map((elem) => {
        if (
          elem &&
          typeof elem === 'object' &&
          elem.id &&
          !(elem instanceof Date)
        ) {
          return elem.id;
        } else if (
          elem &&
          typeof elem === 'object' &&
          !(elem instanceof Date)
        ) {
          return transformBody(elem);
        } else {
          return elem;
        }
      });
    } else if (
      entry[1] &&
      typeof entry[1] === 'object' &&
      entry[1].id &&
      !(entry[1] instanceof Date)
    ) {
      transformedBody[entry[0]] = entry[1].id;
    } else if (
      entry[1] &&
      typeof entry[1] === 'object' &&
      !(entry[1] instanceof Date)
    ) {
      transformedBody[entry[0]] = transformBody(entry[1]);
    } else {
      transformedBody[entry[0]] = entry[1];
    }
  });
  return transformedBody;
};

export const checkAuth: (
  username: string,
  password: string,
) => Promise<User> = (username, password) => {
  const headers = new Headers();
  headers.set('X-User-Login', `${username}`);
  headers.set('Authorization', 'Basic ' + btoa(username + ':' + password));

  return fetch(new URL('/api/user/auth', origin), { method: 'GET', headers })
    .catch((reason) => {
      const error = new Error();
      error.cause = reason;
      error.name = 'Fetch error';
      error.message = 'Network error has occurred';
      throw error;
    })
    .then((response) => {
      if (response.status !== SUCCESS_HTTP_CODE) {
        const error = new Error();
        error.cause = response;
        if (response.status) {
          error.name = response.status + ' ' + response.statusText;
        }
        error.message = 'Error fetching data';
        throw error;
      }
      return response.json().catch((reason) => {
        const error = new Error();
        error.cause = reason;
        error.name = 'Fetch error';
        error.message = 'Server responded with incorrect data';
        throw error;
      });
    });
};

export const authFetch: (
  input: string | URL,
  init?: RequestInit & {
    bodyObj?: object;
    responseType?: 'json' | 'stream' | 'blob';
  },
) => Promise<object | ReadableStream<Uint8Array> | null> = (
  input,
  init = {},
) => {
  const { bodyObj, responseType, ...restInit } = init;
  const fetchInit = { ...restInit, headers: new Headers() };
  fetchInit.headers.set('X-User-Login', `${authProvider.user?.username}`);
  fetchInit.headers.set(
    'Authorization',
    'Basic ' +
      btoa(authProvider.user?.username + ':' + authProvider.user?.password),
  );
  if (
    bodyObj &&
    typeof bodyObj === 'object' &&
    !(bodyObj instanceof FormData)
  ) {
    fetchInit.headers.set('Accept', 'application/json');
    fetchInit.headers.set('Content-Type', 'application/json');
    fetchInit.body = JSON.stringify(transformBody(bodyObj));
  } else if (
    bodyObj &&
    typeof bodyObj === 'object' &&
    bodyObj instanceof FormData
  ) {
    fetchInit.body = bodyObj;
  }
  return fetch(input, fetchInit)
    .catch((reason) => {
      const error = new Error();
      error.cause = reason;
      error.name = 'Fetch error';
      error.message = 'Network error has occurred';
      throw error;
    })
    .then((response) => {
      if (
        response.status !== SUCCESS_HTTP_CODE &&
        response.status !== CREATED_HTTP_CODE
      ) {
        const error = new Error();
        error.cause = response;
        if (response.status) {
          error.name = response.status + ' ' + response.statusText;
          switch (response.status) {
            case FORBIDDEN_HTTP_CODE:
              error.message = 'Insufficient permissions';
              break;
            case NOT_FOUND_HTTP_CODE:
              error.message = 'Not found';
              break;
            case CONFLICT_HTTP_CODE:
              error.message = 'Some field values invalidate unique constrain';
              break;
            default:
              error.message = 'Some server error has occurred';
          }
        } else {
          error.message = 'Network error has occurred';
        }
        throw error;
      }
      if (responseType === 'stream') {
        return response.body;
      }
      if (responseType === 'blob') {
        return response.blob();
      }
      return response.json().catch((reason) => {
        const error = new Error();
        error.cause = reason;
        error.name = 'Fetch error';
        error.message = 'Server responded with incorrect data';
        throw error;
      });
    });
};

export const TrucksQuery: (
  query: Query<{
    search?: number;
    truckNumber?: number;
    status?: TruckStatus[];
    lastLocation?: string;
    distance?: string;
    availableBefore?: Date;
    availableAfter?: Date;
  }>,
) => Promise<Result<Truck>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/truck', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.truckNumber) {
    url.searchParams.append('truckNumber', `${queryParams.truckNumber}`);
  }
  if (queryParams?.status) {
    queryParams?.status.forEach((statusItem) =>
      url.searchParams.append('status', `${statusItem}`),
    );
  }
  if (queryParams?.lastLocation && queryParams?.distance) {
    url.searchParams.append('lastLocation', `${queryParams.lastLocation}`);
    url.searchParams.append('distance', `${queryParams.distance}`);
  }
  if (queryParams?.availableBefore) {
    url.searchParams.append(
      'availableBefore',
      queryParams.availableBefore.toJSON(),
    );
  }
  if (queryParams?.availableAfter) {
    url.searchParams.append(
      'availableAfter',
      queryParams.availableAfter.toJSON(),
    );
  }
  return authFetch(url) as Promise<Result<Truck>>;
};

export const TrucksForMapQuery: (query: {
  queryKey: [key: string];
}) => Promise<TruckForMap[]> = ({ queryKey: [] }) => {
  const url = new URL('/api/truck/forMap', origin);

  return authFetch(url) as Promise<TruckForMap[]>;
};

export const TruckDelete = (userId: string): Promise<Truck> => {
  const url = new URL(`/api/truck/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Truck>;
};

export const TruckCreate = (data: CreateTruck): Promise<Truck> => {
  const url = new URL(`/api/truck`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Truck>;
};

export const TruckUpdate = (data: UpdateTruck): Promise<Truck> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/truck/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Truck>;
};

export const TruckQuery = (query: QuerySingle): Promise<Truck> => {
  const url = new URL(`/api/truck/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Truck>;
};

export const LocationsQuery: (
  query: Query<{ search?: string; name?: string }>,
) => Promise<Result<Location>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/location', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.name) {
    url.searchParams.append('name', `${queryParams.name}`);
  }
  return authFetch(url) as Promise<Result<Location>>;
};

export const LocationDelete = (userId: string): Promise<Location> => {
  const url = new URL(`/api/location/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Location>;
};

export const LocationCreate = (data: CreateLocation): Promise<Location> => {
  const url = new URL(`/api/location`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Location>;
};

export const LocationUpdate = (data: UpdateLocation): Promise<Location> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/location/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Location>;
};

export const LocationQuery = (query: QuerySingle): Promise<Location> => {
  const url = new URL(`/api/location/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Location>;
};

export const LoadsQuery: (
  query: Query<{
    refNumber?: number;
    status?: LoadStatus[];
    loadNumber?: number;
    truckNumber?: number;
  }>,
) => Promise<Result<Load>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/load', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.refNumber) {
    url.searchParams.append('ref', `${queryParams.refNumber}`);
  }
  if (queryParams?.status) {
    queryParams?.status.forEach((statusItem) =>
      url.searchParams.append('status', `${statusItem}`),
    );
  }
  if (queryParams?.loadNumber) {
    url.searchParams.append('loadNumber', `${queryParams.loadNumber}`);
  }
  if (queryParams?.truckNumber) {
    url.searchParams.append('truckNumber', `${queryParams.truckNumber}`);
  }
  return authFetch(url) as Promise<Result<Load>>;
};

export const LoadDelete = (userId: string): Promise<Load> => {
  const url = new URL(`/api/load/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Load>;
};

export const LoadCreate = (data: CreateLoad): Promise<Load> => {
  const url = new URL(`/api/load`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Load>;
};

export const LoadUpdate = (data: UpdateLoad): Promise<Load> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/load/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Load>;
};

export const LoadQuery = (query: QuerySingle): Promise<Load> => {
  const url = new URL(`/api/load/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Load>;
};

export const CustomersQuery: (
  query: Query<{ search?: string }>,
) => Promise<Result<Customer>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/customer', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  return authFetch(url) as Promise<Result<Customer>>;
};

export const CustomerDelete = (userId: string): Promise<Customer> => {
  const url = new URL(`/api/customer/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Customer>;
};

export const CustomerCreate = (data: CreateCustomer): Promise<Customer> => {
  const url = new URL(`/api/customer`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Customer>;
};

export const CustomerUpdate = (data: UpdateCustomer): Promise<Customer> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/customer/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Customer>;
};

export const CustomerQuery = (query: QuerySingle): Promise<Customer> => {
  const url = new URL(`/api/customer/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Customer>;
};

export const FacilitiesQuery: (
  query: Query<{ search?: string; name?: string }>,
) => Promise<Result<Facility>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/facility', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.name) {
    url.searchParams.append('name', `${queryParams.name}`);
  }
  return authFetch(url) as Promise<Result<Facility>>;
};

export const FacilityDelete = (userId: string): Promise<Facility> => {
  const url = new URL(`/api/facility/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Facility>;
};

export const FacilityCreate = (data: CreateFacility): Promise<Facility> => {
  const url = new URL(`/api/facility`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Facility>;
};

export const FacilityUpdate = (data: UpdateFacility): Promise<Facility> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/facility/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Facility>;
};

export const FacilityQuery = (query: QuerySingle): Promise<Facility> => {
  const url = new URL(`/api/facility/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Facility>;
};

export const OwnersQuery: (
  query: Query<{ search?: string; fullName?: number; truckNumber?: number }>,
) => Promise<Result<Owner>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/owner', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.fullName) {
    url.searchParams.append('fullName', `${queryParams.fullName}`);
  }
  if (queryParams?.truckNumber) {
    url.searchParams.append('truckNumber', `${queryParams.truckNumber}`);
  }
  return authFetch(url) as Promise<Result<Owner>>;
};

export const OwnerDelete = (userId: string): Promise<Owner> => {
  const url = new URL(`/api/owner/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Owner>;
};

export const OwnerCreate = (data: CreateOwner): Promise<Owner> => {
  const url = new URL(`/api/owner`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Owner>;
};

export const OwnerUpdate = (data: UpdateOwner): Promise<Owner> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/owner/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Owner>;
};

export const OwnerQuery = (query: QuerySingle): Promise<Owner> => {
  const url = new URL(`/api/owner/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Owner>;
};

export const OwnerDriverCreate = (data: CreateOwnerDriver): Promise<Owner> => {
  const url = new URL(`/api/ownerDriver`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Owner>;
};

export const OwnerDriverUpdate = (data: UpdateOwnerDriver): Promise<Owner> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/ownerDriver/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Owner>;
};

export const OwnerDriverQuery = (query: QuerySingle): Promise<Owner> => {
  const url = new URL(`/api/ownerDriver/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Owner>;
};

export const DriversQuery: (
  query: Query<{
    search?: string;
    fullName?: string;
    allPhone?: string;
    truckNumber?: number;
    owner?: string;
  }>,
) => Promise<Result<Driver>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/driver', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.fullName) {
    url.searchParams.append('fullName', `${queryParams.fullName}`);
  }
  if (queryParams?.allPhone) {
    url.searchParams.append('allPhone', `${queryParams.allPhone}`);
  }
  if (queryParams?.truckNumber) {
    url.searchParams.append('truckNumber', `${queryParams.truckNumber}`);
  }
  if (queryParams?.owner) {
    url.searchParams.append('owner', `${queryParams.owner}`);
  }
  return authFetch(url) as Promise<Result<Driver>>;
};

export const DriverDelete = (userId: string): Promise<Driver> => {
  const url = new URL(`/api/driver/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Driver>;
};

export const DriverCreate = (data: CreateDriver): Promise<Driver> => {
  const url = new URL(`/api/driver`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Driver>;
};

export const DriverUpdate = (data: UpdateDriver): Promise<Driver> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/driver/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Driver>;
};

export const DriverQuery = (query: QuerySingle): Promise<Driver> => {
  const url = new URL(`/api/driver/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Driver>;
};

export const CoordinatorsQuery: (
  query: Query<{
    search?: string;
    fullName?: number;
    truckNumber?: number;
    owner?: string;
  }>,
) => Promise<Result<Coordinator>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/coordinator', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.fullName) {
    url.searchParams.append('fullName', `${queryParams.fullName}`);
  }
  if (queryParams?.truckNumber) {
    url.searchParams.append('truckNumber', `${queryParams.truckNumber}`);
  }
  if (queryParams?.owner) {
    url.searchParams.append('owner', `${queryParams.owner}`);
  }
  return authFetch(url) as Promise<Result<Coordinator>>;
};

export const CoordinatorDelete = (userId: string): Promise<Coordinator> => {
  const url = new URL(`/api/coordinator/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<Coordinator>;
};

export const CoordinatorCreate = (
  data: CreateCoordinator,
): Promise<Coordinator> => {
  const url = new URL(`/api/coordinator`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Coordinator>;
};

export const CoordinatorUpdate = (
  data: UpdateCoordinator,
): Promise<Coordinator> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/coordinator/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Coordinator>;
};

export const CoordinatorQuery = (query: QuerySingle): Promise<Coordinator> => {
  const url = new URL(`/api/coordinator/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Coordinator>;
};

export const CoordinatorDriverCreate = (
  data: CreateCoordinatorDriver,
): Promise<Coordinator> => {
  const url = new URL(`/api/coordinatorDriver`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<Coordinator>;
};

export const CoordinatorDriverUpdate = (
  data: UpdateCoordinatorDriver,
): Promise<Coordinator> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/coordinatorDriver/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<Coordinator>;
};

export const CoordinatorDriverQuery = (
  query: QuerySingle,
): Promise<Coordinator> => {
  const url = new URL(`/api/coordinatorDriver/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<Coordinator>;
};

export const UsersQuery: (
  query: Query<{ search?: string; fullName?: number }>,
) => Promise<Result<User>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/user', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.fullName) {
    url.searchParams.append('fullName', `${queryParams.fullName}`);
  }
  return authFetch(url) as Promise<Result<User>>;
};

export const UserDelete = (userId: string): Promise<User> => {
  const url = new URL(`/api/user/${userId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<User>;
};

export const UserCreate = (data: CreateUser): Promise<User> => {
  const url = new URL(`/api/user`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<User>;
};

export const UserUpdate = (data: UpdateUser): Promise<User> => {
  const { id, ...updatedData } = data;
  const url = new URL(`/api/user/${id}`, origin);
  return authFetch(url, {
    method: 'PATCH',
    bodyObj: updatedData,
  }) as Promise<User>;
};

export const UserQuery = (query: QuerySingle): Promise<User> => {
  const url = new URL(`/api/user/${query.queryKey[1]}`, origin);
  return authFetch(url, {
    method: 'GET',
  }) as Promise<User>;
};

export const FilesQuery: (
  query: Query<{
    linkedTo: string;
    fileOf: FileOfType;
    tags?: Record<string, string>;
  }>,
) => Promise<Result<File>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/file', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams) {
    url.searchParams.append('linkedTo', `${queryParams.linkedTo}`);
    url.searchParams.append('fileOf', `${queryParams.fileOf}`);
  }
  if (queryParams?.tags) {
    Object.entries(queryParams.tags).forEach(([key, value]) => {
      url.searchParams.append(`tags[${key}]`, `${value}`);
    });
  }
  return authFetch(url) as Promise<Result<File>>;
};

export const FileDelete = (fileId: string): Promise<File> => {
  const url = new URL(`/api/file/${fileId}`, origin);
  return authFetch(url, { method: 'DELETE' }) as Promise<File>;
};

export const FileDownloadStream = (
  fileId: string,
): Promise<ReadableStream<Uint8Array>> => {
  const url = new URL(`/api/file/${fileId}/download`, origin);
  return authFetch(url, { method: 'GET', responseType: 'stream' }) as Promise<
    ReadableStream<Uint8Array>
  >;
};

export const FileDownloadBlob = (fileId: string): Promise<Blob> => {
  const url = new URL(`/api/file/${fileId}/download`, origin);
  return authFetch(url, {
    method: 'GET',
    responseType: 'blob',
  }) as Promise<Blob>;
};

export const FileUpload = (data: FormData): Promise<File> => {
  const url = new URL(`/api/file`, origin);
  return authFetch(url, {
    method: 'POST',
    bodyObj: data,
  }) as Promise<File>;
};

export const PushsQuery: (
  query: Query<{ search?: string; truckNumber?: number }>,
) => Promise<Result<Push>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order, queryParams],
}) => {
  const url = new URL('/api/push', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  if (queryParams?.search) {
    url.searchParams.append('search', `${queryParams.search}`);
  }
  if (queryParams?.truckNumber) {
    url.searchParams.append('truckNumber', `${queryParams.truckNumber}`);
  }
  return authFetch(url) as Promise<Result<Push>>;
};

export const EmailsQuery: (
  query: Query<{ state?: string }>,
) => Promise<Result<Email>> = ({
  queryKey: [, page, rowsPerPage, orderBy, order /*, queryParams*/],
}) => {
  const url = new URL('/api/email', origin);
  url.searchParams.append('limit', `${rowsPerPage}`);
  url.searchParams.append('offset', `${rowsPerPage * page}`);
  if (orderBy && order) {
    url.searchParams.append('orderby', `${orderBy}`);
    url.searchParams.append('direction', `${order}`);
  }
  return authFetch(url) as Promise<Result<Email>>;
};
