import React from 'react';
import get from 'lodash/get';
import { useFormik } from 'formik/dist/Formik';

import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import { stopPropagation } from '../utils/stopPropagation';

export default function SelectMultiFieldControl({
  fieldName,
  label,
  placeholder,
  items,
  disabledItems,
  disabled,
  formik,
}: {
  readonly fieldName: string;
  readonly label: string;
  readonly placeholder?: string;
  readonly items: readonly (
    | string
    | { value: string | number; label: string }
  )[];
  readonly disabledItems?: readonly (string | number)[];
  readonly disabled?: boolean;
  readonly formik: ReturnType<typeof useFormik<any>>;
}) {
  const [statusOpen, setStatusOpen] = React.useState<boolean>(false);

  const formikValue = get(formik.values, fieldName);
  const formikError = get(formik.errors, fieldName);
  const formikTouched = get(formik.touched, fieldName);

  const handleStatusKeyUp = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }
    stopPropagation(event);
    handleStatusClose();
  };
  const handleStatusKeyDown = (event: React.KeyboardEvent) => {
    if (event.key !== 'Enter') {
      return;
    }
    stopPropagation(event);
  };

  const handleStatusOpen = () => {
    setStatusOpen(true);
  };
  const handleStatusClose = () => {
    setStatusOpen(false);
    // handleStatusChange();
    queueMicrotask(() => formik.setFieldTouched(fieldName, true, true));
  };

  return (
    <Box key={fieldName} sx={{ height: '75px' }}>
      <FormControl
        fullWidth
        size="small"
        disabled={disabled}
        error={formikTouched && Boolean(formikError)}
      >
        <InputLabel
          id={fieldName}
          htmlFor={'input_id_' + fieldName}
        >{`${label}`}</InputLabel>
        <Select
          labelId={fieldName}
          id={fieldName}
          name={fieldName}
          label={fieldName}
          placeholder={placeholder}
          multiple
          inputProps={{ id: 'input_id_' + fieldName }}
          open={statusOpen}
          value={
            formikValue === undefined || formikValue === null ? [] : formikValue
          }
          renderValue={(selected) => {
            return items
              .filter((item) => {
                const value = typeof item === 'string' ? item : item.value;
                return selected?.includes(value);
              })
              .map((item) => (typeof item === 'string' ? item : item.label))
              .join(', ');
          }}
          onChange={formik.handleChange}
          onOpen={handleStatusOpen}
          onClose={handleStatusClose}
          onKeyUpCapture={handleStatusKeyUp}
          onKeyDownCapture={handleStatusKeyDown}
          onBlur={formik.handleBlur}
        >
          {items.map((item) => (
            <MenuItem
              key={typeof item === 'string' ? item : item.value}
              value={typeof item === 'string' ? item : item.value}
              disabled={
                disabledItems &&
                disabledItems.includes(
                  typeof item === 'string' ? item : item.value,
                )
              }
            >
              <Checkbox
                checked={
                  formikValue?.indexOf(
                    typeof item === 'string' ? item : item.value,
                  ) > -1
                }
                id={typeof item === 'string' ? item : `${item.value}`}
              />
              <ListItemText
                primary={typeof item === 'string' ? item : `${item.label}`}
              />
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formikTouched &&
            formikError &&
            `${
              typeof formikError === 'string' ? formikError : 'Validation error'
            }`}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
