import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { alpha, styled, Theme, CSSObject } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Switch from '@mui/material/Switch';
import { red, grey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PlaceIcon from '@mui/icons-material/Place';
import MapIcon from '@mui/icons-material/Map';
import FactoryIcon from '@mui/icons-material/Factory';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Person4Icon from '@mui/icons-material/Person4';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import BadgeIcon from '@mui/icons-material/Badge';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmailIcon from '@mui/icons-material/Email';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupsIcon from '@mui/icons-material/Groups';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';

import { useAuth } from '../hooks';

const drawerWidth = '240px';

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const boxOpenedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  flexGrow: 1,
  padding: 0,
  width: 'calc(100vw - 240px)',
  height: '100vh',
});

const boxClosedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  flexGrow: 1,
  padding: 0,
  width: 'calc(100vw - 65px)',
  height: '100vh',
});

const RedSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase': {
    color: red[900],
    '&:hover': {
      backgroundColor: alpha(red[900], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: red[900],
  },
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: grey[600],
    '&:hover': {
      backgroundColor: alpha(grey[600], theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: grey[600],
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const StyledBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })(
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  ({ theme, open }) => ({
    ...(open && {
      ...boxOpenedMixin(theme),
    }),
    ...(!open && {
      ...boxClosedMixin(theme),
    }),
  }),
);

export default function Layout({ children }: { children: JSX.Element }) {
  const [isThemeChecked, setIsThemeChecked] = React.useState<boolean>(
    localStorage.getItem('theme') === 'dark',
  );
  const [isTextChecked, setIsTextChecked] = React.useState<boolean>(
    localStorage.getItem('text') === 'bold',
  );
  const [open, setOpen] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const boxContainerRef = React.useRef(null);
  const drawerContainerRef = React.useRef(null);

  const navigate = useNavigate();
  const location = useLocation();
  const { user, signout } = useAuth();
  const theme = useTheme();

  React.useEffect(() => {
    if (location.pathname === '/') {
      setOpen(true);
    }
  }, [location.pathname]);

  const handleThemeChange = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsThemeChecked((checked) => {
      localStorage.setItem('theme', checked ? 'light' : 'dark');
      window.location.reload();
      return !checked;
    });
  };

  const handleTextChange = (event: React.SyntheticEvent) => {
    event.preventDefault();
    setIsTextChecked((checked) => {
      localStorage.setItem('text', checked ? 'normal' : 'bold');
      window.location.reload();
      return !checked;
    });
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleClose();
    signout(() => {
      navigate('/login', { replace: true });
    });
  };

  const handleDrawerOpen = () => {
    !open && setOpen(true);
    if (boxContainerRef.current) {
      const boxDiv = boxContainerRef.current as HTMLElement;
      boxDiv.addEventListener('mousemove', handlerMouseMove);
    }
  };

  const handlerMouseMove = React.useCallback(
    (event: any) => {
      if (drawerContainerRef.current) {
        const drawerDiv = drawerContainerRef.current as HTMLElement;
        const drawerPos = drawerDiv.getBoundingClientRect();
        if (
          event.pageX < drawerPos.left ||
          event.pageX > drawerPos.right ||
          event.pageY < drawerPos.top ||
          event.pageY > drawerPos.bottom
        ) {
          setOpen(false);
          if (boxContainerRef.current) {
            const boxDiv = boxContainerRef.current as HTMLElement;
            boxDiv.removeEventListener('mousemove', handlerMouseMove);
          }
        }
      }
    },
    [drawerContainerRef.current, boxContainerRef.current],
  );

  const handleMenuNavigate = (to: string) => {
    navigate(to, { replace: true });
  };

  const isMenuItemSelected = (itemPath: string): boolean => {
    const itemElements = itemPath.split('/');
    const locationElements = location.pathname.split('/');
    return itemElements.every((el, index) => el === locationElements[index]);
  };

  return (
    <Box sx={{ display: 'flex', padding: 0 }} ref={boxContainerRef}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <img src="/4U_White.png" height={65} style={{ marginTop: -6 }} />
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
          <Typography variant="caption" component="div">
            {'Welcome, ' + user?.fullName}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          // width: '300px',
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            overflow: 'auto',
            boxSizing: 'border-box',
            msOverflowStyle: 'none',
            scrollbarWidth: 'none',
            [`::-webkit-scrollbar`]: {
              display: 'none',
            },
          },
        }}
        ref={drawerContainerRef}
        open={open}
        onMouseEnter={handleDrawerOpen}
      >
        <Toolbar />
        <List>
          <ListItem
            key="Trucks nearby"
            disablePadding
            sx={{ display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/trucks_nearby')}
              onClick={() => handleMenuNavigate('/trucks_nearby')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PlaceIcon />
              </ListItemIcon>
              <ListItemText
                primary="Trucks nearby"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="General Map" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/general_map')}
              onClick={() => handleMenuNavigate('/general_map')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <MapIcon />
              </ListItemIcon>
              <ListItemText
                primary="General Map"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Loads" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/loads')}
              onClick={() => handleMenuNavigate('/loads')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ViewInArIcon />
              </ListItemIcon>
              <ListItemText primary="Loads" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem key="Trucks" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/trucks')}
              onClick={() => handleMenuNavigate('/trucks')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Trucks" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem key="Owners" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={
                isMenuItemSelected('/owners') ||
                isMenuItemSelected('/ownersDrivers')
              }
              onClick={() => handleMenuNavigate('/owners')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <Person4Icon />
              </ListItemIcon>
              <ListItemText primary="Owners" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem key="Coordinators" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={
                isMenuItemSelected('/coordinators') ||
                isMenuItemSelected('/coordinatorsDrivers')
              }
              onClick={() => handleMenuNavigate('/coordinators')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <ContactPhoneIcon />
              </ListItemIcon>
              <ListItemText
                primary="Coordinators"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Drivers" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/drivers')}
              onClick={() => handleMenuNavigate('/drivers')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <BadgeIcon />
              </ListItemIcon>
              <ListItemText primary="Drivers" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <Divider textAlign="left">
            {open ? (
              'Contragents'
            ) : (
              <Avatar sx={{ width: 24, height: 24 }}>C</Avatar>
            )}
          </Divider>
          <ListItem key="Customers" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/customers')}
              onClick={() => handleMenuNavigate('/customers')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <FactoryIcon />
              </ListItemIcon>
              <ListItemText
                primary="Customers"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Facilities" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/facilities')}
              onClick={() => handleMenuNavigate('/facilities')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <WarehouseIcon />
              </ListItemIcon>
              <ListItemText
                primary="Facilities"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <Divider textAlign="left">
            {open ? 'Admin' : <Avatar sx={{ width: 24, height: 24 }}>A</Avatar>}
          </Divider>
          <ListItem key="Locations" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/locations')}
              onClick={() => handleMenuNavigate('/locations')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <AssignmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Locations"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Users" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/users')}
              onClick={() => handleMenuNavigate('/users')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <GroupsIcon />
              </ListItemIcon>
              <ListItemText primary="Users" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <Divider textAlign="left">
            {open ? (
              'System'
            ) : (
              <Avatar sx={{ width: 24, height: 24 }}>S</Avatar>
            )}
          </Divider>
          <ListItem key="Pushs" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/pushs')}
              onClick={() => handleMenuNavigate('/pushs')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <NotificationsIcon />
              </ListItemIcon>
              <ListItemText primary="Pushs" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem key="Emails" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={isMenuItemSelected('/emails')}
              onClick={() => handleMenuNavigate('/emails')}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <EmailIcon />
              </ListItemIcon>
              <ListItemText primary="Emails" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem
            key="Empty"
            disablePadding
            sx={{ height: 'calc(100vh - 765px)', display: 'block' }}
          >
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              disabled={true}
              selected={false}
              onClick={() => {
                return;
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              ></ListItemIcon>
            </ListItemButton>
          </ListItem>
          <ListItem key="Theme_Mode" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={false}
              onClick={handleThemeChange}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: '24px',
                  }}
                >
                  <RedSwitch
                    sx={{
                      marginLeft: '-18px',
                    }}
                    checked={isThemeChecked}
                    onChange={handleThemeChange}
                    inputProps={{ 'aria-label': 'controlled-theme' }}
                  />
                </div>
              </ListItemIcon>
              <ListItemText
                primary={isThemeChecked ? 'Light mode' : 'Dark mode'}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem key="Text_Mode" disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              selected={false}
              onClick={handleTextChange}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <div
                  style={{
                    width: '24px',
                  }}
                >
                  <RedSwitch
                    sx={{
                      marginLeft: '-18px',
                    }}
                    checked={isTextChecked}
                    onChange={handleTextChange}
                    inputProps={{ 'aria-label': 'controlled-text' }}
                  />
                </div>
              </ListItemIcon>
              <ListItemText
                primary={isTextChecked ? 'Normal' : 'Bold'}
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <StyledBox
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        component="main"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        open={open}
      >
        <Toolbar />
        <Paper
          elevation={3}
          sx={{
            backgroundImage:
              theme.palette.mode === 'dark'
                ? 'url("/4U_Logistics_Background_Dark.svg")'
                : 'url("/4U_Logistics_Background_Light.svg")',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: '80% 80%',
            margin: '10px',
            height: 'calc(100% - 84px);',
            width: 'calc(100% - 20px);',
          }}
        >
          {children}
        </Paper>
      </StyledBox>
    </Box>
  );
}
